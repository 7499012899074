import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import Styles from "../styles/user_profile.module.scss";
import Layout from "../components/Layout"
import SEO from "../components/seo"
import PostItem from "../components/postItem";
import ActivityIndicator from "../components/atoms/ActivityIndicator";
import useInfiniteScroll from "../hooks/useInfiniteScroll";
import useDynamicPath from "../hooks/useDynamicPath";
import UserProfileImage from "../components/UserProfileImage";
import amplitude, { logEvent } from "../Analytics";
import { getInitialStateFromSessionStorage } from "../utils/getInitialStateFromSessionStorage";
import { BACK_BUTTON_STR } from "../constant/const";
import { PostTimeLineType } from "../types/models/Post";
import { UserDetailType } from "../types/models/User";
import client from "../apis/client";
import { UserIcon } from "../icons/UserIcon";

const UserProfilePage = ( {
    location
}: any ) => {

    const { objectID: userID } = useDynamicPath( location, "user_profile", "userID" )
    const userName = location.state ? location.state.userName : null
    const [ userData, setUserData ] = useState<UserDetailType | null>( null )
    const [ userPostList, setUserPostList ] = useState<PostTimeLineType[]>( getInitialStateFromSessionStorage( `userPostList_user:${ userID }`, [] ) )
    const [ nextPostURL, setNextPostURL ] = useState<string>( getInitialStateFromSessionStorage( `nextPostURL_user:${ userID }`, "" ) )
    const [ fetchAtLeastOnce, setFetchAtLeastOnce ] = useState( false )
    const [ goingBack, setGoingBack ] = useState( false )
    const [ refreshing, setRefreshing ] = useState( false )

    const userPostListRef = useRef( userPostList )
    const nextPostURLRef = useRef( nextPostURL )


    useEffect( () => {
        userPostListRef.current = userPostList
        sessionStorage.setItem( `userPostList_user:${ userID }`, JSON.stringify( userPostList ) )
    }, [ userPostList ] )

    useEffect( () => {
        nextPostURLRef.current = nextPostURL
        sessionStorage.setItem( `nextPostURL_user:${ userID }`, JSON.stringify( nextPostURL ) )
    }, [ nextPostURL ] )

    useEffect( () => {
        logEvent( amplitude, "Screen-userProfile", { pk: userID, web: true } )
    }, [] )

    const headerOption = {
        headerTitle: userName,
        leftIcon: BACK_BUTTON_STR,
        backButton: true,
    }

    const getUserData = async () => {
        const response_user = await client.get( `/api/users/${ userID }/`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        } );
        const user_data = await response_user.data;
        setUserData( {
            name: user_data.last_name,
            team: user_data.community,
            statusMessage: user_data.status_message,
            profile_image_100: user_data.profile_image_100,
            profile_image_400: user_data.profile_image_400
        } )

        const response_post = await client.get( `/api/users/${ userID }/posts/`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        } );
        const post_data = await response_post.data;
        userPostList.length === 0 && setUserPostList( post_data.results )
        userPostList.length === 0 && setNextPostURL( post_data.next )
        setFetchAtLeastOnce( true )
    }

    useEffect( () => {
        getUserData()
    }, [] )

    const goBack = async () => {
        if ( !nextPostURLRef.current ) { return } setGoingBack( true )
        await client.get( nextPostURLRef.current, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        } ).then( response => {
            const additional_post = response.data.results
            const newUserPostList = userPostListRef.current.concat( additional_post )
            setUserPostList( newUserPostList.slice() )
            setNextPostURL( response.data.next )
        } ).catch( error => {
            console.log( { error } )
        } );

        setTimeout( () => setGoingBack( false ), 500 )

    }

    const refresh = async () => {
        const params = { pk_list: userPostListRef.current.map( post => post.pk ) }
        setRefreshing( true )
        await client.get( `/api/users/${ userID }/posts/latest/`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            params: params
        } )
            .then( response => {
                const additional_post = response.data.result
                const newUserPostList = additional_post.concat( userPostListRef.current )
                setUserPostList( newUserPostList )
            } )
            .catch( error => console.warn( error.response ) );
        setTimeout( () => setRefreshing( false ), 500 )
    }

    const infiniteScroll = useInfiniteScroll( {
        container: typeof document !== 'undefined' ? document : null,
        goBack,
        refresh,
        goingBack,
        refreshing
    } )

    const renderUserData = () => userData &&
        <div className={ Styles.profile_container }>
            <UserProfileImage
                smallImageURL={ userData.profile_image_100 }
                largeImageURL={ userData.profile_image_400 } />
            <div className={ Styles.profile_text_container }>
                <p className={ Styles.name_container }>
                    { userData.name }
                </p>
                { userData.team &&
                    <div className={ Styles.team_container }>
                        <UserIcon style={ { marginRight: 8 } } />
                        <Link to="/team_profile" state={ { teamID: userData.team.pk, teamName: userData.team.name } }>
                            <span>{ userData.team.name }</span>
                        </Link>
                    </div> }
                <p className={ Styles.status_message }>
                    { userData.statusMessage }
                </p>
            </div>
        </div>

    return (
        <Layout headerOption={ headerOption }>
            <>
                <SEO title="UserProfile" />
                { refreshing && <ActivityIndicator /> }
                { !refreshing &&
                    <div className={ Styles.hidden_activity_indicator }>
                        <ActivityIndicator />
                    </div> }
                <div
                    className={ Styles.container }
                    { ...infiniteScroll }>
                    <div className={ Styles.info_container_wrapper }>
                        <div className={ Styles.info_container }>
                            <div>
                                { userData && <UserProfileImage
                                    smallImageURL={ userData.profile_image_100 }
                                    largeImageURL={ userData.profile_image_400 } /> }
                            </div>
                            <div className={ Styles.info_wrapper }>
                                <div className={ Styles.info_number_container }>
                                    <div className={ Styles.info_unit }>
                                        <span>107</span>
                                        <span>投稿</span>
                                    </div>
                                    <div className={ Styles.info_unit }>
                                        <span>520</span>
                                        <span>フォロワー</span>
                                    </div>
                                    <div className={ Styles.info_unit }>
                                        <span>438</span>
                                        <span>フォロー</span>
                                    </div>
                                </div>
                                <div className={ Styles.edit_profile }>
                                    <span>プロフィールを編集</span>
                                </div>
                            </div>
                        </div>
                        <div className={ Styles.under_construction }>
                            <p>現在実装中の機能です</p>
                        </div>
                    </div>
                    { renderUserData() }
                    <div className={ Styles.user_post_container }>
                        { fetchAtLeastOnce && userPostList.length === 0 &&
                            <p style={ {
                                fontSize: 24,
                                color: "rgba(0,0,0,0.54)",
                                textAlign: "center",
                                width: "100%",
                                padding: "12px 24px",
                                lineHeight: 1.4
                            } }>まだ投稿はありません</p>
                        }
                        { userPostListRef.current.map( post => <PostItem post={ post } key={ post.pk } userIconVisible={ false } />
                        ) }
                        { goingBack && <ActivityIndicator /> }
                    </div>
                </div>
            </>
        </Layout>
    );
}

export default UserProfilePage
